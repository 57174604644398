import React from 'react';

import {Box, FireworkContainer, FireworkTextRotator, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  pt: ['3rem', null, null, '4.5rem'],
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  minHeight: [null, null, null, '9.375rem'],
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

export const BelieveSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <Box maxWidth={props.containerMaxWidth} mx="auto" px="1.5rem">
        <StyledFlex>
          <StyledH2 mt={[null, null, null, '0.25rem']} mr="0.75rem" color="neonGreen" fontStyle="italic">
            {copy.we_believe.heading}
          </StyledH2>

          <Box maxWidth="37.125rem" mt={['1.5rem', null, null, 0]}>
            <FireworkTextRotator strings={copy.we_believe.strings} />
          </Box>
        </StyledFlex>
      </Box>
    </Wrapper>
  );
};
