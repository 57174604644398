import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {COMMITMENT_TO_INCLUSION} from 'src/routes/externalUrls';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  backgroundColor: 'neonGreen',
  color: 'darkGreen',
  borderRadius: '1.25rem',
  mx: 'auto',
  px: ['1.5rem', null, null, '3rem'],
  py: ['1.5rem', null, null, '3rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: '40.8125rem',
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: ['1.5rem'],
})``;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'darkGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, 0],
})``;

const SplitBackgroundWrapper = styled(Box)`
  position: relative;
  width: 100%;
  background: ${themeGet('colors.darkGreen')};
`;

const TopBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: ${themeGet('colors.darkGreen')};
`;

const BottomBackground = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: ${themeGet('colors.midnightBlue')};
`;

const ContentWrapper = styled(Box).attrs({
  position: 'relative',
  zIndex: 1,
  py: ['4.5rem'],
  px: ['1.5rem', null, null, '3rem'],
})``;

export const DiversitySection: typeof FireworkContainer = ({...props}) => {
  return (
    <SplitBackgroundWrapper>
      <TopBackground />
      <BottomBackground />
      <ContentWrapper>
        <StyledBox maxWidth={props.containerMaxWidth}>
          <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" alignItems="center">
            <Box maxWidth="43.1875rem">
              <StyledH2>{copy.diversity.heading}</StyledH2>
              <StyledP2>{copy.diversity.description}</StyledP2>
            </Box>
            <Box width={['100%', null, null, 'auto']} minWidth="11.25rem">
              <StyledFireworkCTA
                onClick={(e: MouseEvent) => FireworkCTAClick(e, 'about-us.inclusion', COMMITMENT_TO_INCLUSION)}
                to={COMMITMENT_TO_INCLUSION}
              >
                {copy.diversity.cta}
              </StyledFireworkCTA>
            </Box>
          </Flex>
        </StyledBox>
      </ContentWrapper>
    </SplitBackgroundWrapper>
  );
};
