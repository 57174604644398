import React from 'react';

import {FireworkCTAClick} from 'src/analytics';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {LEADERSHIP_TEAM} from 'src/routes/paths';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

import {Executive, ExecutiveBox, StyledFireworkCTA} from './Executive';

const {H2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'midnightBlue',
  position: 'relative',
})``;

const StyledBox: typeof Box = styled(Box).attrs({
  mx: 'auto',
  padding: '1.5rem',
  gap: ['3rem', null, '1.5rem'],
  display: 'flex',
  flexDirection: 'column',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  flexDirection: ['column', null, 'row'],
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: [null, null, 'wrap'],
  gap: '1.5rem',
})``;

const CtaExecutiveBox: typeof ExecutiveBox = styled(ExecutiveBox)`
  img {
    filter: grayscale(0);
  }

  .firework-cta {
    opacity: 1;
  }
`;

const CtaContainer: typeof Box = styled(Box).attrs({
  backgroundColor: 'midnightBlue',
  width: '100%',
  mt: ['3rem', null, 0],
  pb: [null, null, '100%'],
  position: 'relative',
  top: 0,
  left: 0,
})``;

export const ExecutiveTeamSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <StyledBox maxWidth={props.containerMaxWidth} mx="auto" px="1.5rem">
        <StyledH2>{copy.executives.heading}</StyledH2>

        <StyledFlex>
          {copy.executives.execs.map((exec, i) => (
            <Executive key={i} {...exec} />
          ))}

          <CtaExecutiveBox my={['3rem', null, null, '1.5rem']}>
            <Box position="relative">
              <CtaContainer>
                <StyledFireworkCTA
                  onClick={(e: MouseEvent) => FireworkCTAClick(e, 'about-us.executives.view-all', LEADERSHIP_TEAM)}
                  to={LEADERSHIP_TEAM}
                >
                  {copy.executives.cta}
                </StyledFireworkCTA>
              </CtaContainer>
            </Box>
          </CtaExecutiveBox>
        </StyledFlex>
      </StyledBox>

      <Box width="100%" height="1px" mt={['3rem', null, null, '4.5rem']} backgroundColor="periwinkle" />
    </Wrapper>
  );
};
